/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//视频相关
const video = {
    name: 'video',
    apis: {
        /**
         * 视频列表获取
         * @param params
         * @returns {*}
         */
        videoList(params = { skip:0, limit:0 }) {
            return httpFetch('get', '/api/video', params);
        },
		/**
		 *
		 * @param params
		 * @returns {Promise<T>}
		 */
		videoListSimple(params){
			return httpFetch('post', `/api/video/videoList`, params);
		},
        /**
         * 视频详情
         */
        videoShow(videoId){
            return httpFetch('get', `/api/video/${videoId}`);
        },
        /**
         * 视频发布
         * @param params
         * @title 标题。
         * @state 视频状态
         * @imageInfo 封面
         * @videoInfo 视频
         * @address 地址
         * @step 科目几
         * @examId 考场
         * @readCount 阅读数
         */
        videoPost(
            params = {
                title: '',
                state: '',
                imageInfo: undefined,
                videoInfo: undefined,
                address: undefined,
                step: undefined,
                examId: undefined,
                provinceCode:undefined,//省
                cityCode:undefined,//市
                readCount: undefined
            }
        ) {
            return httpFetch('post', '/api/video', params);
        },
        /**
         * 收藏
         * @param params
         * videoId 视频id
         * @returns {*}
         */
        collect(params={videoId:undefined}){
            return httpFetch('post', `/api/video/collect`, params);
        },
        /**
         * 取消收藏
         * @param params
         * videoId 视频id
         * @returns {*}
         */
        collectDestroy(params={videoId:undefined}){
            return httpFetch('post', `/api/video/collectDestroy`, params);
        },
        /**
         * 当前用户收藏list
         * @returns {*}
         */
        collectList(){
            return httpFetch('get', `/api/video/collectList`);
        },

		dueVideoResource(){
			return httpFetch('post', `/api/video/dueVideoResource`);
		}

    }
};
export default video;
