/** @format */

import httpFetch from 'src/util/httpFetch';
//customize自定义价格
const customize = {
    name: 'customize',
    apis: {
		//	通过订单回溯教练的exam
		adminCustomizeEdit(params){
			return httpFetch('post', '/api/customize/adminCustomizeEdit',params);
		},
		//自动校准定制价格
		updateCustomize(params){
			return httpFetch('post', `/api/customize/updateCustomize`,params);
		},
    }
};
export default customize;
